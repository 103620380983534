import { navigate } from "gatsby";
import React from "react";
import Button from "../components/form/button/Button";
import { staticPage } from "../../config/staticPage";
import Layout from "../layout/Layout";

function AccountSuccessPage() {
  return (
    <>
      <h3>Your account has been successfully created.</h3>
      <Button onClick={() => navigate("/")}>continue shopping</Button>
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "account-success") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

AccountSuccessPage.Layout = Layout
export default AccountSuccessPage;
